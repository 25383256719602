import axios, { auth, getUrl } from '../helpers/axios'
import { isInvitationPartner } from '@/helpers'
import * as Sentry from '@sentry/vue'

export const customerService = {
  setCustomer,
  getCustomer,
  postCustomer,
  getHDNCompanies,
  getHDNCompanyLabels,
  getIwizeToken
}

function setCustomer (invitationType) {
  // Invitation
  const invitation = {
    customer_id: 99999,
    id: 'aBcDeFGhIJK',
    partner_invited: 0,
    send_email: 1,
    submitted_at: null,
    type: invitationType,
    valid_until: '2030-01-01T09:00:00.000000Z'
  }

  // Address
  const address = {
    addition: null,
    number: '46',
    place: 'Groningen',
    postcode: '9712VL',
    street: 'Noorderhaven'
  }

  // Partner
  const partner = {
    address: {},
    date_of_birth: '25/09/1986',
    email: 'jolien@rapide.software',
    first_name: 'Jolien',
    full_name: 'Jolien Hemmen',
    id: 99998,
    initials: 'J.',
    is_child: false,
    is_main_customer: false,
    is_partner: true,
    different_address: false,
    last_name: 'Hemmen',
    maritial_status: 9,
    mobile: null,
    non_resident: 0,
    passed_away: false,
    phone: null,
    salutation: 'Mevr.',
    sex: 0,
    suffix: ''
  }

  const office = {
    style: {},
    city: null,
    code: 'Unive',
    email: 'info@unvice.nl',
    house_number: null,
    house_number_addition: null,
    name: 'Unive',
    number: '7',
    phone: '0505499735',
    postcode: null,
    street_name: null,
    website: 'https://www.unive.nl'
  }

  const children = [{
    id: 6380,
    full_name: 'S.L. Stahl (Sophie)',
    initials: 'S.L.',
    first_name: 'Sophie',
    official_first_names: 'Sophie Lieselot',
    suffix: '',
    last_name: 'Stahl',
    date_of_birth: '28/01/2016',
    maritial_status: 9,
    sex: 0,
    phone: null,
    mobile: null,
    email: null,
    non_resident: 0,
    passed_away: false,
    is_main_customer: false,
    is_partner: false,
    is_child: true,
    studies: 0,
    lives_at_home: true
  }, {
    id: 6383,
    full_name: 'J.I. Stahl (Juliët)',
    initials: 'J.I.',
    first_name: 'Juliët',
    official_first_names: 'Juliët Isabel',
    suffix: '',
    last_name: 'Stahl',
    date_of_birth: '10/07/2018',
    maritial_status: 9,
    sex: 0,
    phone: null,
    mobile: null,
    email: null,
    non_resident: 0,
    passed_away: false,
    is_main_customer: false,
    is_partner: false,
    is_child: true,
    studies: 0,
    lives_at_home: true
  }, {
    id: 6386,
    full_name: 'M.J. Stahl (Milou)',
    initials: 'M.J.',
    first_name: 'Milou',
    official_first_names: 'Milou Jente',
    suffix: '',
    last_name: 'Stahl',
    date_of_birth: '25/11/2019',
    maritial_status: 9,
    sex: 0,
    phone: null,
    mobile: null,
    email: null,
    non_resident: 0,
    passed_away: false,
    is_main_customer: false,
    is_partner: false,
    is_child: true,
    studies: 0,
    lives_at_home: true
  }]

  const pensions = []

  const mortgages = [{
    id: 1,
    onderpandsgegevens: null,
    redenHypotheek: null,
    hypotheciareInschrijving: null,
    datumTaxatie: null,
    wozWaarde: 0,
    wozDatum: null,
    totaalSom: 260000,
    bouwdepot: 0,
    passeerdatum: null,
    verkopendMakelaar_id: null,
    hdn_company_id: null,
    nhg: null,
    rate: null,
    rangorde: 1,
    status: 0,
    getekend: null,
    voor_verlenging: null,
    na_verlening: null,
    financiering: null,
    bankgarantie: null,
    passeerdatumVoortgang: null,
    collateral_address: null,
    collateral_city: null,
    collateral_postcode: null,
    collateral_housenumber: null,
    collateral_county: null,
    restantHoofdsom: 205834,
    executiewaarde: 0,
    type_woning: 0,
    offerteNummer: null,
    datumAanvraag: null,
    datumOfferte: null,
    datumGeldigheidOfferte: null,
    datumOfferteGetekend: null,
    vovVoorVerbouwing: 0,
    vovNaVerbouwing: 0,
    ewVoorVerbouwing: 0,
    ewNaVerbouwing: 0,
    trackingNumber: null,
    collateral_addition: null,
    validity_unsigned_offer_date: null,
    validity_signed_offer_date: null,
    end_financing_reservation_date: null,
    has_bank_guarantee_date: null,
    bank_guarantee_date: null,
    arrangement_type: null,
    existing_mortgage_number: null,
    own_resources_amount: null,
    mutation_code: null,
    is_property_purchase: null,
    bank_guarantee_costs: null,
    net_consultancy_costs: null,
    mortgage_deed_cost_specification: null,
    national_mortgage_guarantee_costs: null,
    fine_interest: null,
    loan_type: null,
    loan_to_value: 0,
    risk_class: 0,
    risk_class_type: null,
    parts: {
      data: [{
        id: 1,
        mortgage_id: 1,
        hypotheeknummer: null,
        leningdeelnummer: null,
        soortLening: null,
        hoofdsom: 260000,
        begindatum: '2014-08-31T00:00:00+02:00',
        einddatum: '2044-08-31T00:00:00+01:00',
        rente: 1.89,
        rentevasteperiode: null,
        einddatumRentevast: null,
        created_at: '2021-06-04T09:23:07+02:00',
        updated_at: '2021-06-04T09:23:19+02:00',
        begindatumRentevast: null,
        renteType: null,
        duur: 360,
        hypotheekType: null,
        afsluitprovisie: null,
        bedenktijd: 0,
        bedenktijdStartDatum: null,
        type: null,
        agency_code: null,
        tax_box_type: null,
        repayment_type: 2,
        interest_agreement_type: null,
        interest_cooling_off_period_type: null,
        interest_cooling_off_period_in_months: null,
        payment_term: null,
        consumptive_percentage: null,
        consumptive_amount: null,
        national_mortgage_guarantee: null,
        coverage_percentage: null,
        rate_based_on: null,
        remaining_debt: 205834.00,
        remaining_debt_date: '2021-06-04T07:23:07.000000Z',
        monthly_deposit: null,
        current_value: null,
        lowest_deposit_any_year: null,
        highest_deposit_any_year: null,
        interest_deduction_end_date: null,
        interest_type: null,
        mortgage_guarantee: null,
        remaining_deb_last_year: null
      }]
    }
  }]

  // Return partner as customer
  if (isInvitationPartner(invitationType)) {
    partner.partner = { data: {} }
    partner.address = { data: address }
    partner.children = { data: children }
    partner.mortgages = { data: mortgages }
    partner.pensions = { data: pensions }
    partner.invitation = { data: invitation }

    // Return partner as customer
    return new Promise((resolve, reject) => {
      resolve({
        data: partner
      })
    })
  }

  // Return customer with partner
  return new Promise((resolve, reject) => {
    resolve({
      data: {
        address: { data: address },
        children: { data: children },
        date_of_birth: '12/06/1982',
        email: 'stefan@rapide.software',
        first_name: 'Stefan',
        official_first_names: 'Stefan',
        full_name: 'Stefan Stahl',
        id: 99999,
        initials: 'S.S.',
        office: { data: office },
        invitation: { data: invitation },
        is_child: false,
        is_main_customer: true,
        is_partner: false,
        last_name: 'Stahl',
        mortgages: { data: mortgages },
        maritial_status: 9,
        mobile: '0612345678',
        non_resident: 0,
        partner: { data: partner },
        passed_away: false,
        pensions: { data: pensions },
        phone: '0612345678',
        salutation: 'Dhr.',
        sex: 1,
        suffix: ''
      }
    })
  })
}

function getCustomer (invitationToken) {
  let url = `${getUrl()}/api/portal/customer?includes=address,partner,children,employments,office,office.style,partner.address,invitation.aqopi_info,mortgages,mortgages.parts,pensions`

  if (invitationToken) {
    url += `&token=${invitationToken}`
  }

  return axios.get(url)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.error(error)
      Sentry.captureException(error)
    })
}

/* eslint-disable */
function getIwizeToken(invitationId) {
  return axios.post(
    `${getUrl()}/api/portal/iwize/token/by_portal_invitation`,
    {portal_invitation_id: invitationId}
  ).then(response => {
    return response
  }).catch(function (error) {
    console.error(error)
    Sentry.captureException(error)
  })
}

function postCustomer(data, files) {
  if (files.length > 0) {
    Sentry.addBreadcrumb({
      level: 'info',
      message: 'preparing files for submit',
    })

    files.forEach((file) => {
      console.log('File:', file.type, file.filename)
      const formData = new FormData()
      if (file.type === 'PDF' || file.type === 'application/pdf') {
        const blob = pdfToBlob(file)
        if (blob !== null) {
          formData.append('file', blob, file.filename + '.pdf')
        }
      } else if (file.type === 'XML') {
        const blob = xmlToBlob(file)
        if (blob !== null) {
          formData.append('file', blob, file.filename + '.xml')
        }
      }

      // Only PDF and XML
      const supported = ['PDF', 'application/pdf', 'XML']
      if (supported.includes(file.type)) {
        const request = new XMLHttpRequest()
        request.open('POST', `${getUrl()}/api/portal/file`)
        request.setRequestHeader('Authorization', auth())
        request.send(formData)
      }
    })
  }

  Sentry.addBreadcrumb({
    level: 'info',
    message: 'remove params that contains html tags',
  })

  // remove params that contains html tags
  if (
    data?.sustainability?.sections?.section_2?.questions?.report_sustainability_housing?.description &&
    data?.sustainability?.sections?.section_4?.questions?.financing_sustainability?.description
  ) {
    delete data.sustainability.sections.section_2.questions.report_sustainability_housing.description
    delete data.sustainability.sections.section_4.questions.financing_sustainability.description
  }

  Sentry.addBreadcrumb({
    level: 'info',
    message: 'submitting customer data',
  })

  return axios.put(`${getUrl()}/api/portal/customer`, data)
    .then(response => {
      if (response.status !== 200) {
        Sentry.addBreadcrumb({
          type: 'error',
          level: 'warning',
          message: 'Response status not 200',
          data: {
            status: response.status || null,
            statusText: response.statusText || null,
            data: response.data || null,
          }
        })

        Sentry.captureException(response)
      }

      return response
    })
      .catch((error) => {
        if (error.response.status === 422) {
          let errors = {}
          if (error.response.data?.error?.errors) {
            let errorKeys = Object.keys(error.response.data.error.errors)
            errorKeys.forEach((field) => {
              error.response.data.error.errors[field].forEach((errorMessage) => {
                if (typeof errors[field] === "undefined") {
                  errors[field] = []
                }
                errors[field].push(errorMessage)
              })
            })
          }
          Sentry.addBreadcrumb({
            type: 'error',
            level: 'error',
            message: 'Validation errors',
            data: {
              errors: errors,
            }
          })
        } else {
          Sentry.addBreadcrumb({
            type: 'error',
            level: 'error',
            message: error.response.status + ' response',
            data: {
              error: error.response.data.error,
            }
          })
        }

        let errorCustomerData = ''

        if (error.hasOwnProperty('config')
            && error.config.hasOwnProperty('data')
            && JSON.parse(error.config.data)
        ) {
          let parsedErrorConfigData = JSON.parse(error.config.data)

          if (parsedErrorConfigData.hasOwnProperty('customer')) {
            errorCustomerData = JSON.stringify(parsedErrorConfigData.customer)
          }

          delete error.config.data
        }

        Sentry.addBreadcrumb({
          type: 'error',
          level: 'error',
          message: 'Setting up additional error data.',
          data: {
            code: error.code || null,
            message: error.message || null,
            name: error.name || null,
            stack: error.stack || null,
            status: error.status || error?.response?.status || null,
          },
        })

        const modifiedError = new Error(error.message || 'postCustomer error occurred')
        modifiedError.name = error.name || null
        modifiedError.code = error.code || null

        /**
         * Important: "error" is too big for Sentry.captureException(error).
         * Not captured in Sentry and without any Sentry error response.
         * A modified error object is created and sent to Sentry.
         *
         * Possible reference: https://docs.sentry.io/concepts/data-management/size-limits/
         */
        Sentry.captureException(
          modifiedError,
          {
            extra: {
              error: {
                message: error?.message,
                url: error?.config?.url,
                method: error?.config?.method,
              },
              config_data: {
                customer: errorCustomerData,
              },
              response: {
                status: error?.response?.status,
                statusText: error?.response?.statusText,
                body: error?.response?.request?.response,
              },
            }
          }
        )

        throw error
      })
}

function getHDNCompanies() {
  return axios.get(`${getUrl()}/api/portal/hdn_company/active?perPage=1000&orderBy=name&direction=asc`)
    .then(response => {
      return {status: 200, data: response.data.data}
    }).catch(() => {
      return {status: 200, data: []}
    })
}

function getHDNCompanyLabels() {
  return axios.get(`${getUrl()}/api/portal/hdn_company_label/active?perPage=1000&orderBy=name&direction=asc`)
    .then(response => {
      return {status: 200, data: response.data.data}
    }).catch(() => {
      return {status: 200, data: []}
    })
}

function xmlToBlob(file) {
  console.log('Generate.xmlToBlob', file.filename)
  try {
    const raw = window.atob(file.payload)
    const uInt8Array = new Uint8Array(raw.length)
    for (let i = 0; i < raw.length; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }
    console.log('Generated:', file.filename, 'application/xml', raw.length)
    return new Blob([uInt8Array], {type: 'application/xml'})
  } catch (e) {
    console.error('Failed: ', file.filename)
    Sentry.captureException(error)
  }
  return null
}

function pdfToBlob(file) {
  console.log('Generate.pdfToBlob', file.filename)
  try {
    const parts = file.payload.split(';base64,')
    const contentType = parts[0].split(':')[1]
    const raw = window.atob(parts[1])
    const uInt8Array = new Uint8Array(raw.length)
    for (let i = 0; i < raw.length; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }
    console.log('Generated:', file.filename, contentType, raw.length)
    return new Blob([uInt8Array], {type: contentType})
  } catch (e) {
    console.error('Failed: ', file.filename)
    Sentry.captureException(e)
  }
  return null
}
